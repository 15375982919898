body {
  margin: 0;
  font-family: 'Avenir Next LT Pro', 'Avenir Next', 'Helvetica Neue', Arial,
    Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: local('Avenir Next LT Pro'),
    url('/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Ink Free';
  src: url('/fonts/InkFree.woff2') format('woff2'),
    url('/fonts/InkFree.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Regular'),
    url('/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'),
    url('/fonts/Montserrat-Bold.ttf') format('truetype');
}
